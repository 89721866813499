<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form>
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                    <div class="flex items-center">
                        <InfoButton :content="infoContent" />
                        <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div v-if="toggleValue" v-loading="$waiting.is('loading-fixedAssets')" class="flex flex-col">
                    <el-checkbox v-model="fixedAssetsData.addDepreciation" :disabled="inputDisabled">Avskrivningar</el-checkbox>
                    <el-checkbox v-model="fixedAssetsData.addIntangibleAssets" :disabled="inputDisabled">{{ fixedAssetsData.intangibleAssets.title }}</el-checkbox>
                    <div v-show="fixedAssetsData.addIntangibleAssets" class="flex flex-col pl-8 pb-8">
                        <el-checkbox v-model="fixedAssetsData.intangibleAssets.addGoodwill" :disabled="inputDisabled">Goodwill</el-checkbox>
                        <el-checkbox v-model="fixedAssetsData.intangibleAssets.addDeprecationPerItem" :disabled="inputDisabled">Avskrivning per post</el-checkbox>
                        <el-table v-show="fixedAssetsData.intangibleAssets.addDeprecationPerItem" :data="fixedAssetsData.intangibleAssets.deprecationPerItems">
                            <el-table-column label="Beskrivning" width="480">
                                <template slot-scope="{ row }">
                                    <el-input v-model="row.description" :disabled="inputDisabled" />
                                </template>
                            </el-table-column>
                            <el-table-column label="Procent/År">
                                <template slot-scope="{ row }">
                                    <el-input v-model="row.depreciationTime" :disabled="inputDisabled" />
                                </template>
                            </el-table-column>
                            <el-table-column>
                                <template slot-scope="{ $index, store, row }">
                                    <template v-if="store.states.data.length !== 1">
                                        <el-button plain size="mini" @click="deleteRowIntangibleAssets(row)" :disabled="inputDisabled">
                                            <i class="fa-solid fa-minus" />
                                        </el-button>
                                    </template>
                                    <template v-if="$index === store.states.data.length - 1">
                                        <el-button plain size="mini" @click="addRowIntangibleAssets" :disabled="inputDisabled">
                                            <i class="fa-solid fa-plus" />
                                        </el-button>
                                    </template>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-checkbox v-model="fixedAssetsData.addTangibleAssets" :disabled="inputDisabled">{{ fixedAssetsData.tangibleAssets.title }}</el-checkbox>
                    <div v-show="fixedAssetsData.addTangibleAssets" class="pl-8 flex flex-col">
                        <el-checkbox v-model="fixedAssetsData.tangibleAssets.addNonLinearDepreciation" :disabled="inputDisabled">Ej linjär avskrivning</el-checkbox>
                        <el-checkbox v-model="fixedAssetsData.tangibleAssets.addResidualDepreciation" :disabled="inputDisabled">Avskrivning till restvärde</el-checkbox>
                        <el-checkbox v-model="fixedAssetsData.tangibleAssets.addDeprecationPerItem" :disabled="inputDisabled">Avskrivning per post</el-checkbox>
                        <el-table v-show="fixedAssetsData.tangibleAssets.addDeprecationPerItem" :data="fixedAssetsData.tangibleAssets.deprecationPerItems">
                            <el-table-column label="Beskrivning" width="480">
                                <template slot-scope="{ row }">
                                    <el-input v-model="row.description" :disabled="inputDisabled" />
                                </template>
                            </el-table-column>
                            <el-table-column label="Procent/År">
                                <template slot-scope="{ row }">
                                    <el-input v-model="row.depreciationTime" :disabled="inputDisabled" />
                                </template>
                            </el-table-column>
                            <el-table-column>
                                <template slot-scope="{ $index, store, row }">
                                    <template v-if="store.states.data.length !== 1">
                                        <el-button plain size="mini" @click="deleteRowTangibleAssets(row)" :disabled="inputDisabled">
                                            <i class="fa-solid fa-minus" />
                                        </el-button>
                                    </template>
                                    <template v-if="$index === store.states.data.length - 1">
                                        <el-button plain size="mini" @click="addRowTangibleAssets" :disabled="inputDisabled">
                                            <i class="fa-solid fa-plus" />
                                        </el-button>
                                    </template>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-form>
            <CardEditButtons :toggle-value="toggleValue" :show-edit-button="showEditButton" :toggle-edit="toggleEdit" :close-modal="closeModal" :save-data="saveData" />
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        tooltipInfo: {
            type: String,
            default: "",
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        fixedAssetsData: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            selectedYear: null,
            financialYearId: null,
            annualReportId: undefined,
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            infoContent: `Enligt K2 (BFNAR 2016:10) punkt 18.4 st 1, ska tillämpad avskrivningstid anges för varje post. Avskrivningstiden anges på rubriken Avskrivning per post.
            <br /><br />
            Immateriella anläggningstillgångar: K2 (BFNAR 2016:10) kapitel 18 punkt 4
            <br /><br />
            Avskrivning per post: Enligt 4 kap 4 § ÅRL ska anläggningstillgångar med begränsad 
            nyttjandeperiod skrivas av systematiskt över denna period. Om nyttjandeperioden för 
            immateriella anläggningstillgångar som utgörs av utgifter för företagets eget 
            utvecklingsarbete inte kan fastställas med en rimlig grad av säkerhet, ska perioden anses 
            uppgå till fem år. Enligt 5 kap 9 § ÅRL ska ett företag som redovisar goodwill som en 
            tillgång, lämna upplysningar om tillämpad avskrivningsperiod och skälen för denna.
            <br /><br />
            Avskrivning per post: K2 (BFNAR 2016:10) - ange avskrivningstid, t.ex 5 år.
            <br /><br />
            Goodwill: Enligt 5 kap. 9 § ska ett företag som redovisar goodwill som tillgång lämna 
            upplysningar om tillämpad avskrivningsperiod och skälen för denna.
            K2 (BFNAR 2016:10) kapitel 18 punkt 3. 
            <br /><br />
            Materiella anläggningstillgångar: K2 (BFNAR 2016:10) kapitel 18 punkt 4
            <br /><br />
            Avskrivning per post: K2 (BFNAR 2016:10) - ange avskrivningstid, t.ex 5 år.
            <br /><br />
            Ej linjär avskrivning: Enligt K2 (BFNAR 2016:10) punkt 18.4 st 2 ska ett företag som 
            fördelar avskrivningen på annat sätt än linjärt lämna upplysning om hur avskrivningen 
            fördelas.
            <br /><br />
            Avskrivning till restvärde: Enligt K2 (BFNAR 2016:10) punkt 18.4 st 3 ska ett företag som 
            skriver av en tillgång till restvärdet lämna upplysning om det.
            <br /><br />
            Exempel: Inventarier, verktyg och installationer består i huvudsak av lastbilar. 
            Vid fastställande av det avskrivningsbara beloppet för dessa har hänsyn tagits till 
            beräknat restvärde.`,
            localInputTextPrincipalRule: this.InputTextPrincipalRule,
            localInputTextOptionRule: this.InputTextOptionRule,
            localInputTextOptionRuleIndirectCosts: this.InputTextOptionRuleIndirectCosts,
            initialInputText: "",
            isInitialInputTextSet: false,
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("../../managementStatement/components/CardEditButtons.vue"),
        InfoButton: () => import("../../managementStatement/components/InfoButton.vue"),
    },
    watch: {
        inputText(newVal) {
            this.localInputTextPrincipalRule = newVal;
            if (!this.isInitialInputTextSet) {
                this.initialInputText = newVal;
                this.isInitialInputTextSet = true;
            }
        },
        localInputTextPrincipalRule(newVal) {
            this.$emit("update:text", newVal);
        },
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
    },
    methods: {
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 4,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        async saveData() {
            if (this.validateDeprecationPerPost()) return;
            this.$emit("saveData");
            this.localInputTextPrincipalRule = this.inputText;
            this.isInitialInputTextSet = false;
            this.toggleEdit();
            this.$notify.success({ title: 'Sparat "Anläggningstillgångar"' });
        },
        validateDeprecationPerPost() {
            if (this.fixedAssetsData.intangibleAssetsRequired) {
                const intangibleAssetsDeprecationList = this.fixedAssetsData.intangibleAssets.deprecationPerItems;
                const hasValues = intangibleAssetsDeprecationList.some(item => item.description !== "" && item.depreciationTime !== "");
                if (!hasValues || !this.fixedAssetsData.intangibleAssets.addDeprecationPerItem) {
                    this.$notify.error({ title: 'Fyll i minst en rad i "Avskrivning per post" för immateriella anläggningstillgångar' });
                    return true;
                }
            }

            if (this.fixedAssetsData.tangibleAssetsRequired) {
                const tangibleAssetsDeprecationList = this.fixedAssetsData.tangibleAssets.deprecationPerItems;
                const hasValues = tangibleAssetsDeprecationList.some(item => item.description !== "" && item.depreciationTime !== "");
                if (!hasValues || !this.fixedAssetsData.tangibleAssets.addDeprecationPerItem) {
                    this.$notify.error({ title: 'Fyll i minst en rad i "Avskrivning per post" för materiella anläggningstillgångar' });
                    return true;
                }
            }
        },
        closeModal() {
            this.$emit("close");
            const load = "loading-fixedAssets";
            this.$emit("close", { load: load, inputTextNewValue: this.inputTextNewValue });
            this.toggleEdit();
        },
        addRowIntangibleAssets() {
            const newObj = {
                description: "",
                depreciationTime: "",
            };
            this.fixedAssetsData.intangibleAssets.deprecationPerItems.push(newObj);
        },
        deleteRowIntangibleAssets(row) {
            const intanglibleAssetsDeprecationList = this.fixedAssetsData.intangibleAssets.deprecationPerItems;
            if (intanglibleAssetsDeprecationList.length > 0) {
                const index = intanglibleAssetsDeprecationList.indexOf(row);
                if (index > -1) {
                    intanglibleAssetsDeprecationList.splice(index, 1);
                }
            }
        },
        addRowTangibleAssets() {
            const newObj = {
                description: "",
                depreciationTime: "",
            };
            this.fixedAssetsData.tangibleAssets.deprecationPerItems.push(newObj);
        },
        deleteRowTangibleAssets(row) {
            const tanglibleAssetsDeprecationList = this.fixedAssetsData.tangibleAssets.deprecationPerItems;
            if (tanglibleAssetsDeprecationList.length > 0) {
                const index = tanglibleAssetsDeprecationList.indexOf(row);
                if (index > -1) {
                    tanglibleAssetsDeprecationList.splice(index, 1);
                }
            }
        },
    },
    computed: {
        inputTextNewValue() {
            return this.initialInputText != this.localInputTextPrincipalRule;
        },
    },
};
</script>
